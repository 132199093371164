import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Ringkjøbing Landbobank',
  bankId: 24,
  logoWidth: 180,
  color: '#2c8b41',
  googleAnalytics: {
    test: 'UA-53256967-14',
    production: 'UA-53256967-7'
  },
  forceOverride: false,
  backgroundGradient: ['#f5f5f5', '#f5f5f5'],
  backgroundImage: '24_bg.jpg',
  topBar: { background: '#26923c', color: 'white' },
  body: { headlineColor: '#424242', bodyColor: '#424242' }
};

export default config;
